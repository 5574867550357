<template>
  <div class="apply3">
    <van-nav-bar
      title="选择认证类型"
      :border="false"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      
      <van-cell-group :border="false">
        <van-cell title-class="title_class" label-class="label_class" :border="false">
          <template #title>
            请选择认证类型
          </template>
          <template #label>
            请按经营情况选择，完成后不可更改
          </template>
        </van-cell>
      </van-cell-group>
      <van-radio-group v-model="shoptype">
        <van-cell-group :border="false">
          <van-cell :border="false" center clickable @click="shoptype = '1'" is-link>
            <template #title>
              小微商户
            </template>
            <template #icon>
              <van-radio name="1" checked-color="#EE0A24" />
            </template>
            <template #label>
              <div>适合电商法第十条不需要办理市场主体登记的个人。</div>
              <div class="tishi">收入可提现至个人银行卡</div>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group :border="false">
          <van-cell :border="false" center clickable @click="shoptype = '2'" is-link>
            <template #title>
              个体工商户
            </template>
            <template #icon>
              <van-radio name="2" checked-color="#EE0A24" />
            </template>
            <template #label>
              <div>适合营业执照类型为个体工商户，如经营类型包含食品，请提供食品经营许可证。</div>
              <div class="tishi">收入提现至法人银行卡</div>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group :border="false">
          <van-cell :border="false" center clickable @click="shoptype = '3'" is-link>
            <template #title>
              企业/公司
            </template>
            <template #icon>
              <van-radio name="3" checked-color="#EE0A24" />
            </template>
            <template #label>
              <div>适合营业执照类型为有限责公司，股份有限公司的商户</div>
              <div class="tishi">收入提现至对公账户</div>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="next_btn">
      <van-button block type="danger" @click="commition">下一步</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "GonghuoApply3",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      shoptype: 0,
      regid: 0,
    }
  },
  mounted() {
    this.regid = this.$route.query.regid;
    this.shoptype = this.$route.query.shoptype ? this.$route.query.shoptype : 0;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "选择认证类型");
    let gonghuo1 = null;
    gonghuo1 = localStorage.getItem("gonghuo1");
    if (gonghuo1) {
      console.log(this.shoptype);
      gonghuo1 = this.$qs.parse(gonghuo1);
      if (gonghuo1.shoptype > 0) {
        this.shoptype = gonghuo1.shoptype;
        console.log(this.shoptype);
      } 
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    commition() {
      if (parseInt(this.shoptype) <= 0) {
        this.$toast("请选择认证类型");
        return false;
      }
      let gonghuo1 = null;
      gonghuo1 = localStorage.getItem("gonghuo1");
      if (gonghuo1) {
        gonghuo1 = this.$qs.parse(gonghuo1);
        gonghuo1.shoptype = parseInt(this.shoptype);
        console.log(gonghuo1);
        localStorage.setItem("gonghuo1", this.$qs.stringify(gonghuo1));
      }
      this.$router.push({
        name: "GonghuoApply4",
        query: {
          regid: this.regid,
          shoptype: this.shoptype,
          shareid: this.shareid,
          merchid: this.merchid,
          t: new Date().getTime()
        }
      });
    }
  }
}
</script>
<style lang="less">
.apply3 {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 2;
  background-color: #fff;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .content {
    overflow: hidden;
    padding-bottom: 90px;
    .van-cell-group {
      background-color: #ededed;
      &:last-of-type{
        padding-bottom: 10px;
      }
      padding-top: 10px;
      .van-cell {
        background-color: #fff;
        .van-radio__icon {
          margin-right: 20px;
        }
        .van-cell__title {
          flex: 1;
          text-align: left;
          font-size: 18px;
          font-weight: bold;
        }
        .van-cell__label{
          font-weight: normal;
          font-size: 14px;
          margin-top: 10px;
        }
        .title_class{
          text-align: center;
          font-size: 20px;
          font-weight: bold;
        }
        .label_class{
          text-align: center;
        }
        .tishi {
          color: #EA6B48;
          margin-top: 10px;
        }
        .van-cell__right-icon {
          font-size: 20px;
          margin-left: 10px;
        }
      }
    } 
  }
  .next_btn {
    padding: 20px;
    position: fixed;
    left: 0;
    right: 0;
    bottom:0;
    background-color: #fff;
  }
}
</style>